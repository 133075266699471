import { PM } from '~classes/enums.js';
import { microservices } from '~classes/microservices.js';
import urlHelpers from '~tools/url-helpers.js';
import { isLengthyArray, checkImage, companyOrderingBackgroundURL } from '~tools/helpers.js';
import Vue from 'vue';

//const cookieNavigationKey = "BlitzItNavigationSession";

//{
//        displayName: 'Items',   //name that is displayed in titles and menus
//        name: 'items',          //route-name that actually navigates to
//        singleName: 'item'      //route-name that actually navigates to single version
//        archiveName: 'archive'  //route-name that navigates to archive if exists
//        aliases: [],            //a list of route names that also use these properties
//        icon: String,           //icon to display in menu (ie. mdi-home)
//        isInNavMenu: true,          //show in menu *defaults to true
//        isSubmenu: true,        //show as a submenu of parent but not in nav menu (in Horizontal Navbar)
//        isAuth: true,           //user needs to be logged in *defaults to true
//        subscriptions: [],      //subscriptions for permissions and preferences
//        permissions: [],        //permissions for access
//        children: [],           //sub menu items
//        microservice: String    //the name of the microservice to help find the base URL
//        path: String            //UNIQUE//the path to the right store (api path is best)
//        title: String           //breadcrumb and page title
//        subFilters: []          //subcode to filter navigation items to
//        hasNavigation: true,
//        hasAppBar: true
//        hesitate: false         //when navigating away, prompt the user to make sure
//        themeColour: string

//        externalParties: Array  //possible 3rd party entities (ie. xero, myob)
//        externalPartyPath: String
//        externalPartyCredentialNavigation: String //what navigation name to look for connected external parties
//        canPushToExternalParty: true
//        canPullFromExternalParty: true

//        externalParties: Array  //3rd party entities (ie. xero, myob)
//        syncPath: String        //the path to the right syncing store
//        syncLoadingIDs: Array   //the entityName for the sync loader

//        localCacheDays: Number  //number of days till reset from cache

//}

//export const navigation = {    
export default {   
    install(vue, { router }) {
        const root = new Vue({
            data: {
                isTraining: false,
                pageTitle: null,
                backgroundName: null,
                backgroundID: null,
                backgroundURL: null,
                appBar: false,
                appNavigation: false,
                hesitate: false,
                history: [],
                rightSidebar: null, //{ name, id } OR this._uid
                mUseCustomCompanyBackground: null,
                failedBackgroundIDs: [],
                goodBackgroundIDs: [],
                useCustomCompanyBackground: false
            },
         });

        this.session = root;

        this.doShow = (item) => {
            if (!root.$BlitzIt.auth.session.isLoggedIn && item.requiresAuth) {
                return false;
            }

            //check children
            if (item.children != null && item.children.length > 0) {
                for (var i = 0; i < item.children.length; i++) {
                    if (this.doShow(item.children[i])) {
                        return true;
                    }
                }
            }

            //if no children are true and no name, then do not show
            if (!item.name) {
                return false;
            }
            
            return root.$BlitzIt.auth.doShow(item.subscriptions, item.permissions, null);
        }

        this.isInSubFilters = (navName) => {
            var navItem = this.findItem(navName);
            if (navItem == null) {
                return false;
            }

            if (!isLengthyArray(navItem.subFilters)) {
                return true;
            }

            return navItem.subFilters.some(x => x == root.$BlitzIt.auth.session.subscriptionCode);
        }

        this.findSubmenus = (navName) => {
            var navItem = this.findItem(navName);
            if (navItem.children) {
                return navItem.children
                .filter(x => x.isSubmenu !== false)
                .filter(x => root.$BlitzIt.auth.doShow(x))
            }
        },

        this.unloadF = (e) => {
            e.preventDefault();
            e.returnValue = 'Test';
        },

        this.navBack = () => {
            if (this.session.history.length > 1) {
                this.session.history.pop();
                var n = this.session.history[this.session.history.length - 1];
                this.session.history.pop();
                router.push(n);
            }
            else {
                router.back();
            }
        }
    },
    findItemIn(items, name) {
        for (var i = 0; i < items.length; i++) {
            var tItem = items[i];

            if (tItem.name && tItem.name == name ||
                tItem.singleName && tItem.singleName == name ||
                isLengthyArray(tItem.aliases) && tItem.aliases.some(y => y == name)) {
                return tItem;
            }

            if (tItem.children != undefined && tItem.children != null) {
                var res = this.findItemIn(tItem.children, name);

                if (res != null) {
                    return res;
                }
            }
        }

        return null;
    },
    findItem(name) {
        if (!name) {
            return null;
        }

        return this.findItemIn(this.items, name);
        //return res == null ?  : res;
    },
    findConvertFunc(nav, partyName) {
        var item = this.findItem(nav);
        if (item != null && item.externalNavigations != null) {
            var extItem = item.externalNavigations.find(x => x.name == partyName);
            if (extItem != null) {
                return extItem.convertFunc;
            }
        }

        return null;
    },
    findPath(navName) {
        var navItem = this.findItem(navName);
        var vPath = null;

        if (navItem && navItem.microservice) {
            vPath = urlHelpers.getURL(navItem.microservice);
        }

        if (navItem && navItem.path) {
            vPath = vPath.appendURL(navItem.path);
        }
                
        if (vPath != null) {
            if (vPath[vPath.length - 1] === '/') {
                return vPath.slice(0, vPath.length - 1);
            }
            else {
                return vPath;
            }
        }
        else {
            return '';
        }
    },
    findDisplayName(navName) {
        var navItem = this.findItem(navName);
        return navItem ? navItem.displayName : null
    },
    findIcon(navName) {
        var navItem = this.findItem(navName);
        return navItem ? navItem.icon : null;
    },
    findLocalCacheExpiryDays(navName) {
        var navItem = this.findItem(navName);
        return navItem ? navItem.localCacheDays || 7 : 7;
    },
    findSingleDisplayName(navName) {
        var navItem = this.findItem(navName);
        if (navItem) {
            var lth = navItem.displayName.length;
            if (navItem.displayName[lth - 1] == 's') {
                return navItem.displayName.substring(0, lth - 1);
            }
            else {
                return navItem.displayName;
            }
        }
        return '';
    },
    findMultiName(navName) {        
        var navItem = this.findItem(navName);
        return navItem.name ? navItem.name : navItem.singleName;
    },
    findSingleName(navName) {
        var navItem = this.findItem(navName);
        return navItem.singleName ? navItem.singleName : navItem.name;
    },
    findStoreID(navName) {
        var navItem = this.findItem(navName);
        return navItem.storeID ? navItem.storeID : 'id';
    },
    findArchiveName(navName) {
        var navItem = this.findItem(navName);
        return navItem.archiveName ? navItem.archiveName : null;
    },  
    findIsAuth(navName) {
        var item = this.findItem(navName);

        if (item == null) {
            //no item found so assume no auth is needed;
            return false;
        }
        
        if (item.isAuth == null || item.isAuth === true) {
            return true;
        }
        
        return false;
    },
    updateCustomHistory(to, from) {
        //if was new and remaining, don't push
        //if next is same as from except no new then don't log it

        if (from != null && to.name == from.name && (from.params?.id == 'new' || from.query.id == 'new')) {
            this.session.history.pop();
        }

        if (to != null && (to.params == null || to.params.navIgnore !== true)) {
            this.session.history.push(to);
        }
    },
    updateURL() {
        var bID = this.session.backgroundID ?? this.session.$BlitzIt.auth.session.data?.companyAccountID;
        if (bID != null && !this.session.failedBackgroundIDs.some(x => x == bID)) {
            this.session.backgroundURL = companyOrderingBackgroundURL(bID);
        }
        else {
            this.session.backgroundURL = `/img/${this.session.backgroundName ?? 'home'}-background.jpg`;
        }
    },
    updateBackgroundID(id) {
        if (id == null || this.session.goodBackgroundIDs.some(x => x == id)) {
            //update
            this.session.backgroundID = id;
            this.updateURL();
        }
        else if (this.session.failedBackgroundIDs.some(x => x == id)) {
            //do nothing
            this.session.backgroundID = null;
            this.updateURL();
        }
        else {
            //test
            const url = companyOrderingBackgroundURL(id);
            checkImage(
                url,
                () => {
                    this.session.goodBackgroundIDs.push(id);
                    this.session.backgroundID = id;
                    this.updateURL();
                },
                () => {
                    this.session.failedBackgroundIDs.push(id);
                    this.session.backgroundID = null;
                    this.updateURL();
                }
            )
        }
    },
    updateBackground(navName) {
        var item = this.findItem(navName);

        if (item != null && item.background != null) {
            this.session.backgroundName = item.background;
        }
        else {
            this.session.backgroundName = null;
        }

        if (item != null && item.hasNavigation != null) {
            this.session.appNavigation = !item.hasNavigation;
        }
        else {
            this.session.appNavigation = true;
        }

        if (item != null && item.hasAppBar === false) {
            this.session.appBar = false;
        }
        else {
            this.session.appBar = true;
        }

        this.updateURL();
    },
    updateHesitation(navName) {
        var item = this.findItem(navName);

        if (this.session.hesitate) {
            window.removeEventListener('beforeunload', this.unloadF);
        }

        if (item != null) {
            this.session.hesitate = item.hesitate === true;
        }
        else {
            this.session.hesitate = false;
        }

        if (this.session.hesitate) {
            window.addEventListener('beforeunload', this.unloadF);
        }
    },
    updateSidebar(id) {
        if (name == null) {
            this.session.rightSidebar = null;
        }
        else {
            this.session.rightSidebar = id;
        }
    },
    isTraining() {
        return this.session.isTraining;
    },
    findSubscriptionIn(items, name) {        
        for (var i = 0; i < items.length; i++) {
            var tItem = items[i];
            
            if (tItem.name != undefined && tItem.name != null && tItem.name == name) {
                if (tItem.subscriptions == undefined || tItem.subscriptions == null) {
                    return [];
                }
                return tItem.subscriptions;
            }


            if (tItem.children != undefined && tItem.children != null) {
                var res = this.findSubscriptionIn(tItem.children, name);

                if (res != null) {
                    return res;
                }
            }
        }

        return null;
    },
    findSubscriptions(name) {
        var res = this.findSubscriptionIn(this.items, name);        
        return res == null ? [] : res;
    },
    findPermissions(name) {
        // var res = this.findPermissionsIn(this.items, name);
        // return res == null ? [] : res;
        var navItem = this.findItem(name);
        if (navItem == null) {
            return [];
        }

        var rItems = [];

        if (isLengthyArray(navItem.permissions)) {
            rItems.push(...navItem.permissions);
        }
        // if (isLengthyArray(navItem.editPermissions)) {
        //     rItems.push(...navItem.editPermissions);
        // }

        return rItems;
    },
    findEditPermissions(name) {
        var navItem = this.findItem(name);
        if (navItem == null) {
            return [];
        }

        var rItems = [];

        if (isLengthyArray(navItem.permissions)) {
            rItems.push(...navItem.permissions);
        }
        if (isLengthyArray(navItem.editPermissions)) {
            rItems.push(...navItem.editPermissions);
        }

        return rItems;
    },
    findExternalParties(navName) {
        var navItem = this.findItem(navName);
        return isLengthyArray(navItem.externalParties) ? navItem.externalParties : [];
    },
    findExternalPartyPath(navName) {
        var navItem = this.findItem(navName);
        var vPath = null;

        if (navItem && navItem.microservice) {
            vPath = urlHelpers.getURL(navItem.microservice);
        }

        if (navItem && navItem.externalPartyPath) {
            vPath = vPath.appendURL(navItem.externalPartyPath);
        }
                
        if (vPath != null) {
            if (vPath[vPath.length - 1] === '/') {
                return vPath.slice(0, vPath.length - 1);
            }
            else {
                return vPath;
            }
        }
        else {
            return '';
        }
    },
    findExternalPartyCredentialNavigation(navName) {
        var navItem = this.findItem(navName);
        if (navItem != null && navItem.externalPartyCredentialNavigation != null) {
            return navItem.externalPartyCredentialNavigation;
        }
        return null;
    },
    findCanPushToExternalParty(navName) {
        var navItem = this.findItem(navName);

        if (navItem.canPushToExternalParty != null && navItem.canPushToExternalParty === false) {
            return false;
        }

        var parties = this.findExternalParties(navName);
        var syncPath = this.findExternalPartyPath(navName);

        if (parties != null && parties.length > 0 && syncPath != null) {
            return navItem.canPushToExternalParty != null ? navItem.canPushToExternalParty : false;
        }

        return false
    },
    findCanPullFromExternalParty(navName) {
        var navItem = this.findItem(navName);

        if (navItem.canPullFromExternalParty != null && navItem.canPullFromExternalParty === false) {
            return false;
        }

        var parties = this.findExternalParties(navName);
        var syncPath = this.findExternalPartyPath(navName);

        if (parties != null && parties.length > 0 && syncPath != null) {
            return navItem.canPullFromExternalParty != null ? navItem.canPullFromExternalParty : false;
        }

        return false
    },
    getNavNames() {
        return this.getNavNamesOfChildren(this.items);
        //return Object.values(this.items);
    },
    getNavNamesOfChildren(children) {
        var navNames = children.filter(y => y.name != null).map(x => x.name);
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (isLengthyArray(child.children)) {
                var childNames = this.getNavNamesOfChildren(child.children);
                childNames.forEach(x => { navNames.push(x); });
            }
        }
        return navNames;
    },
    items: [
        {
            isAuth: false,
            isInNavMenu: false,
            name: 'home',
            themeColour: '#192233'
        },
        {
            isInNavMenu: false,
            children: [
                {
                    name: 'office-portal',
                    background: 'office',
                },
                {
                    name: 'office-portal-home',
                    background: 'office'
                },
                {
                    name: 'dashboard',
                    background: 'office',
                },
                {
                    name: 'setup',
                    background: 'office'
                },
                {
                    name: 'customer-dashboard',
                    background: 'customer',
                    subscriptions: ['CUSTFREE']
                },
                {
                    name: 'procurement-dashboard',
                    background: 'office',
                    subscriptions: ['CUST', 'SUPP']
                },
                {
                    name: 'supplier-dashboard',
                    background: 'office',
                    subscriptions: ['SUPP']
                },
                {
                    name: 'journey-statistics',
                    background: 'office',
                    subscriptions: ['COUR', 'SUPP']
                },
                {
                    name: 'customers-board',
                    background: 'office'
                },
                {
                    name: 'products-board',
                    background: 'office'
                },
                {
                    name: 'order-slots-board',
                    background: 'office'
                },
                {
                    name: 'users-board',
                    background: 'office'
                },
                {
                    isInNavMenu: false,
                    displayName: 'Delivery Review Hub',
                    icon: 'mdi-truck-delivery',
                    name: 'delivery-review-hub',
                    // background: 'driver',
                    subscriptions: ['COUR'],
                    permissions: ['journey-optimizer'],
                    //subFilters: ['COUR','SUPP'],
                    // hasAppBar: false,
                    // hasNavigation: true,
                    // hesitate: true
                },
                {
                    isInNavMenu: false,
                    displayName: 'Dispatch Hub',
                    icon: 'mdi-clipboard-arrow-right',
                    name: 'dispatch-hub',
                    permissions: ['dispatcher'],
                    subscriptions: ['SUPP','COUR','CUST','CUSTFREE','SUPPFREE','COURFREE'],
                },
                {
                    isInNavMenu: false,
                    displayName: 'Driver Hub',
                    icon: 'mdi-truck-delivery',
                    name: 'driver-hub',
                    background: 'driver',
                    subscriptions: ['COUR'],
                    permissions: ['driver'],
                    subFilters: ['COUR','SUPP'],
                    hasAppBar: false,
                    hasNavigation: true,
                    hesitate: true
                },
                {
                    isInNavMenu: false,
                    displayName: 'Journey Hub',
                    icon: 'mdi-truck',
                    name: 'template-optimization-hub',
                    subscriptions: ['COUR'],
                    permissions: ['journey-optimizer']
                },
                {
                    isInNavMenu: false,
                    displayName: 'Optimization Hub',
                    icon: 'mdi-routes',
                    name: 'journey-optimization-hub',
                    permissions: ['journey-optimizer'],
                    subscriptions: ['COUR'],
                    subFilters: ['COUR','SUPP']
                },
                {
                    isInNavMenu: false,
                    displayName: 'Order Sheet Hub',
                    icon: 'mdi-list-box',
                    background: 'office',
                    name: 'order-sheets-hub',
                    permissions: ['sales-manager'],
                    subscriptions: ['SUPP'],
                    subFilters: ['SUPP'],
                },
                {
                    isInNavMenu: false,
                    displayName: 'Packing Hub',
                    icon: 'mdi-package-variant',
                    background: 'stock',
                    subscriptions: ['SUPP'],
                    name: 'packing-hub',
                    permissions: ['packer']
                },
                {
                    isInNavMenu: false,
                    displayName: 'Picking Hub',
                    icon: 'mdi-clipboard',
                    background: 'stock',
                    name: 'picking-hub',
                    subscriptions: ['SUPP'],
                    permissions: ['picker'],
                    //hesitate: true
                },
                {
                    isInNavMenu: false,
                    displayName: 'Production Hub',
                    icon: 'mdi-factory',
                    background: 'production',
                    name: 'production-hub',
                    permissions: ['manufacturer'],
                    subscriptions: ['SUPP'],
                    subFilters: ['SUPP']
                },
                {
                    isInNavMenu: false,
                    displayName: 'Sales Hub',
                    icon: 'mdi-receipt',
                    background: 'office',
                    name: 'sales-hub',
                    permissions: ['sales-manager'],
                    subscriptions: ['SUPP'],
                    subFilters: ['SUPP'],
                    hasNavigation: true,
                },
                {
                    isInNavMenu: false,
                    displayName: 'Stock Balancer Hub',
                    icon: 'mdi-cube-outline',
                    background: 'stock',
                    name: 'stock-balancer-hub',
                    aliases: ['beta-stock-balancer-hub'],
                    permissions: ['stock-manager'],
                    subscriptions: ['CUST', 'SUPP'],
                    subFilters: ['CUST', 'SUPP'],
                    hesitate: true
                },
                {
                    isInNavMenu: false,
                    displayName: 'Stock Checker Hub',
                    icon: 'mdi-cube-outline',
                    background: 'stock',
                    name: 'stock-checker-hub',
                    permissions: ['stock-manager'],
                    subscriptions: ['CUST', 'SUPP'],
                    subFilters: ['CUST', 'SUPP'],
                    hesitate: true
                },
                // {
                //     isInNavMenu: false,
                //     displayName: 'Stock Import Hub',
                //     icon: 'mdi-home-import-outline',
                //     background: 'stock',
                //     name: 'stock-import-hub',
                //     subscriptions: ['CUST', 'SUPP'],
                //     permissions: ['stock-manager']
                // },
            ]
        },
        {
            displayName: 'Ordering',
            icon: 'mdi-receipt',
            children: [
                {
                    displayName: 'Purchase Orders',
                    icon: 'mdi-file-outline',
                    background: 'customer',
                    name: 'purchase-orders',
                    singleName: 'purchase-order',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['purchase-orders'],
                    microservice: microservices.ORDERING,
                    path: 'PurchaseOrders'
                },
                {
                    displayName: 'Customer Orders',
                    icon: 'mdi-file-outline',
                    name: 'customer-orders',
                    singleName: 'customer-order',
                    aliases: ['customer-orders-import', 'customer-orders-list'],
                    subscriptions: ['SUPP'],
                    permissions: ['customer-orders,sales-manager'],
                    microservice: microservices.ORDERING,
                    path: 'CustomerOrders',
                    subFilters: ['COUR','SUPP']
                },
                {
                    displayName: '3PL Orders',
                    icon: 'mdi-file-outline',
                    name: 'fulfiller-orders',
                    singleName: 'fulfiller-order',
                    subscriptions: ['COUR'],
                    permissions: ['customer-orders,sales-manager'],
                    microservice: microservices.ORDERING,
                    path: 'Fulfiller/PurchaseOrders',
                    subFilters: ['COUR','SUPP']
                },
                {
                    displayName: 'Standing Orders',
                    icon: 'mdi-file-outline',
                    name: 'standing-purchase-orders',
                    singleName: 'standing-purchase-order',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['standing-purchase-orders'],
                    microservice: microservices.ORDERING,
                    path: 'Customer/StandingOrders',
                    subFilters: ['CUSTFREE','CUST']
                },
                {
                    displayName: 'Standing Orders',
                    icon: 'mdi-file-refresh-outline',
                    name: 'standing-customer-orders',
                    singleName: 'standing-customer-order',
                    subscriptions: ['SUPP'],
                    permissions: ['standing-customer-orders'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/StandingOrders',
                    subFilters: ['COUR','SUPP']
                },
                {
                    displayName: 'Process Schedules',
                    isInNavMenu: false,
                    icon: 'mdi-alarm-multiple',
                    name: 'customer-order-schedules',
                    singleName: 'customer-order-schedule',
                    subscriptions: ['SUPP'],
                    permissions: ['process-schedules'],
                    microservice: microservices.ORDERING,
                    path: 'CustomerOrderSchedules',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Order Actions',
                    isInNavMenu: false,
                    icon: 'mdi-electron-framework',
                    name: 'ordering-actions',
                    singleName: 'ordering-action',
                    subscriptions: ['SUPP'],
                    permissions: ['ordering-functions'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/FunctionCommands',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Courier Invoicing Actions',
                    isInNavMenu: false,
                    icon: 'mdi-function',
                    name: 'courier-invoicing-actions',
                    singleName: 'courier-invoicing-action',
                    subscriptions: ['COUR'],
                    permissions: ['courier-invoicing-actions'],
                    microservice: microservices.COURIERINVOICING,
                    path: 'CourierInvoicing/FunctionCommands',
                    subFilters: ['COUR']
                },
            ]
        },
        {
            displayName: 'Stock',
            icon: 'mdi-clipboard-pulse-outline',
            children: [
                {
                    displayName: 'Batches',
                    icon: 'mdi-cube-outline',
                    name: 'batches',
                    singleName: 'batch',
                    subscriptions: ['SUPP'],
                    permissions: ['stock-batches'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/EntryItemBatches'
                },
                {
                    displayName: 'Batch Archives',
                    isInNavMenu: false,
                    icon: 'mdi-cube-outline',
                    name: 'batch-archives',
                    singleName: 'batch-archive',
                    aliases: ['batch-tracking'],
                    subscriptions: ['SUPP'],
                    permissions: ['stock-batches'],
                    microservice: microservices.STOCKARCHIVING,
                    path: 'StockArchiving/Batches'
                },
                {
                    displayName: 'Batch Adjustment Archives',
                    isInNavMenu: false,
                    icon: 'mdi-cube-outline',
                    name: 'batch-adjustment-archives',
                    singleName: 'batch-adjustment-archive',
                    subscriptions: ['SUPP'],
                    permissions: ['stock-batches'],
                    microservice: microservices.STOCKARCHIVING,
                    path: 'StockArchiving/BatchAdjustments'
                },
                {
                    displayName: 'Consignments',
                    icon: 'mdi-clipboard-outline',
                    name: 'stock-consignments',
                    singleName: 'stock-consignment',
                    aliases: ['stock-transfer'],
                    subscriptions: ['CUST', 'SUPP'],
                    permissions: ['stock-consignments, stock-manager, sales-manager, driver'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/Consignments'
                },
                {
                    displayName: 'Imports',
                    icon: 'mdi-clipboard-outline',
                    name: 'stock-imports',
                    singleName: 'stock-import',
                    subscriptions: ['CUST','SUPP'],
                    permissions: ['stock-imports, stock-manager'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/Imports'
                },
                {
                    isInNavMenu: false,
                    name: 'picking-slips',
                    subscriptions: ['SUPP'],
                    permissions: ['picker'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/PickingSlips',
                },
                {
                    isInNavMenu: false,
                    displayName: 'Consignments',
                    icon: 'mdi-clipboard-outline',
                    name: 'stock-consignment-fulfilling',
                    subscriptions: ['SUPP'],
                    permissions: ['stock-consignments'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/ConsignmentFulfilling'
                },
                {
                    isInNavMenu: false,
                    name: 'instant-transfers',
                    subscriptions: ['SUPP'],
                    permissions: ['stock-transfers', 'stock-manager'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/Transfers'
                },
                {
                    displayName: 'Stock Items',
                    icon: 'mdi-cube-outline',
                    name: 'stock-items',
                    singleName: 'stock-item',
                    subscriptions: ['SUPP'],
                    permissions: ['stock-items'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/EntryItems'
                },
                {
                    displayName: 'Stock Adjustments',
                    icon: 'mdi-cube-send',
                    name: 'stock-adjustments',
                    singleName: 'stock-adjustment',
                    subscriptions: ['SUPP'],
                    permissions: ['stock-adjustments'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/EntryItemAdjustments'
                },
                {
                    isInNavMenu: false,
                    displayName: 'Stock Increments',
                    icon: 'mdi-counter',
                    name: 'stock-increments',
                    singleName: 'stock-increment',
                    subscriptions: ['SUPP'],
                    permissions: ['stock-increments'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/Increments'
                },
                {
                    isInNavMenu: false,
                    displayName: 'Stock Tracked Items',
                    icon: 'mdi-radar',
                    name: 'stock-tracked-items',
                    singleName: 'stock-tracked-item',
                    aliases: ['stock-levels', 'stock-tracked-items-correct'],
                    subscriptions: ['CUSTFREE'],
                    permissions: ['stock-levels'],
                    microservice: microservices.STOCKTRACKING,
                    path: 'StockTracking/StockEntryItems'
                },
                {
                    isInNavMenu: false,
                    name: 'stock-tracked-items-status',
                    permissions: ['stock-manager,stock-levels'],
                    microservice: microservices.STOCKTRACKING,
                    path: 'StockTracking/StockEntryItemStatus'
                },
                {
                    displayName: 'Stock Thresholds',
                    icon: 'mdi-clipboard-arrow-up-outline',
                    name: 'stock-thresholds',
                    singleName: 'stock-threshold',
                    subscriptions: ['CUST'],
                    permissions: ['stock-thresholds'],
                    microservice: microservices.STOCKTRACKING,
                    path: 'StockTracking/Thresholds'
                },
                {
                    displayName: 'Supply Lines',
                    icon: 'mdi-source-fork',
                    name: 'supply-lines',
                    singleName: 'supply-line',
                    subscriptions: ['CUST'],
                    permissions: ['supply-lines'],
                    microservice: microservices.STOCKTRACKING,
                    path: 'StockTracking/SupplyLines'
                },
                {
                    isInNavMenu: false,
                    displayName: 'Supply Pointers',
                    icon: 'mdi-source-fork',
                    name: 'supply-pointers',
                    singleName: 'supply-pointer',
                    subscriptions: ['SUPP'],
                    permissions: ['supply-pointers'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'StockManaging/SupplyPointers'
                },
                // {
                //     isInNavMenu: false,
                //     displayName: 'Stock Balancer',
                //     icon: 'mdi-clipboard-pulse',
                //     name: 'stock-balancer',
                //     subscriptions: ['CUST'],
                //     permissions: ['stock-levels', 'stock-transfers', 'stock-batches', 'locations'],
                //     subFilters: ['SUPP']
                // },
                {
                    displayName: '3PL Pointers',
                    icon: 'mdi-vector-point',
                    isInNavMenu: false,
                    name: 'fulfiller-pointers',
                    singleName: 'fulfiller-pointer',
                    subscriptions: ['SUPP'],
                    permissions: ['fulfiller-pointers'],
                    microservice: microservices.ORDERING,
                    path: 'Ordering/FulfillerPointers'
                },
                {
                    displayName: 'Archives',
                    icon: 'mdi-cube-outline',
                    name: 'transfer-archives',
                    singleName: 'transfer-archive',
                    subscriptions: ['SUPP'],
                    permissions: ['stock-transfers'],
                    microservice: microservices.STOCKARCHIVING,
                    path: 'StockArchiving/Transfers'
                },
                {
                    displayName: 'Import Archives',
                    icon: 'mdi-cube-outline',
                    isInNavMenu: false,
                    name: 'import-archives',
                    singleName: 'import-archive',
                    subscriptions: ['SUPP'],
                    permissions: ['stock-transfers'],
                    microservice: microservices.STOCKARCHIVING,
                    path: 'StockArchiving/Imports'
                },
                {
                    isInNavMenu: false,
                    name: 'stock-user-activities',
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/UserActivities'
                },
            ]
        },
        {
            displayName: 'Dispatch',
            icon: 'mdi-truck-fast-outline',
            children: [
                {
                    displayName: 'Consignments',
                    icon: 'mdi-clipboard-outline',
                    name: 'customer-consignments',
                    singleName: 'customer-consignment',
                    subscriptions: ['COUR'],
                    permissions: ['courier-packing, sales-manager'],
                    microservice: microservices.COURIERING,
                    path: 'Supplier/CourierConsignments',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Consignments',
                    icon: 'mdi-clipboard-outline',
                    name: 'supplier-consignments',
                    singleName: 'supplier-consignment',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['courier-packing'],
                    microservice: microservices.COURIERING,
                    path: 'Customer/CourierConsignments',
                    subFilters: ['CUSTFREE','CUST']
                },
                {
                    displayName: 'Courier Orders',
                    icon: 'mdi-clipboard-outline',
                    name: 'courier-orders',
                    singleName: 'courier-order',
                    archiveName: 'courier-order-archives',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['courier-orders'],
                    microservice: microservices.COURIERING,
                    path: 'Client/CourierOrders',
                    subFilters: ['CUSTFREE','CUST','SUPP']
                },   
                {
                    displayName: 'Archived Courier Orders',
                    isInNavMenu: false,
                    name: 'courier-order-archives',
                    singleName: 'courier-order-archive',
                    subscriptions: ['COUR'],
                    permissions: ['courier-orders'],
                    microservice: microservices.COURIERARCHIVING,
                    path: 'ClientArchiving/CourierOrders',
                    subFilters: ['CUSTFREE','CUST','SUPP']
                },
                {
                    isInNavMenu: false,
                    name: 'courier-order-fulfilling',
                    subscriptions: ['COUR'],
                    permissions: ['courier-orders'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/CourierOrderFulfilling'
                },                
                {
                    displayName: 'Client Orders',
                    icon: 'mdi-clipboard-outline',
                    name: 'client-orders',
                    singleName: 'client-order',
                    archiveName: 'client-order-archives',
                    subscriptions: ['COUR'],
                    permissions: ['client-orders'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/CourierOrders',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Client Orders',
                    isInNavMenu: false,
                    name: 'client-single-orders',
                    singleName: 'client-single-order',
                    subscriptions: ['COUR'],
                    permissions: ['client-orders'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/CourierOrders'
                },
                {
                    displayName: 'Archived Client Order',
                    isInNavMenu: false,
                    name: 'client-order-archives',
                    singleName: 'client-order-archive',
                    subscriptions: ['COUR'],
                    permissions: ['client-orders'],
                    microservice: microservices.COURIERARCHIVING,
                    path: 'CourierArchiving/CourierOrders',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Movements',
                    icon: 'mdi-truck-fast-outline',
                    name: 'courier-movements',
                    singleName: 'courier-movement',
                    subscriptions: ['COUR'],
                    permissions: ['movements'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Movements',
                    subFilters: ['COUR','SUPP']
                },
                {
                    displayName: 'Releases',
                    icon: 'mdi-clipboard-arrow-right-outline',
                    name: 'releases',
                    singleName: 'release',
                    archiveName: 'release-archives',
                    subscriptions: ['COUR'],
                    permissions: ['releases'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Releases',
                    subFilters: ['SUPP','COUR']
                },
                {
                    displayName: 'Release Archives',
                    isInNavMenu: false,
                    icon: 'mdi-clipboard-arrow-right-outline',
                    name: 'release-archives',
                    singleName: 'release-archive',
                    subscriptions: ['COUR'],
                    permissions: ['releases'],
                    microservice: microservices.COURIERARCHIVING,
                    path: 'CourierArchiving/Releases',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Receivals',
                    icon: 'mdi-clipboard-arrow-down-outline',
                    name: 'receivals',
                    singleName: 'receival',
                    archiveName: 'receival-archives',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['receivals'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Receivals',
                    subFilters: ['CUSTFREE','CUST','SUPP','COUR']
                },
                {
                    displayName: 'Receival Archives',
                    isInNavMenu: false,
                    icon: 'mdi-clipboard-arrow-down-outline',
                    name: 'receival-archives',
                    singleName: 'receival-archive',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['receivals'],
                    microservice: microservices.COURIERARCHIVING,
                    path: 'CourierArchiving/Receivals',
                    subFilters: ['CUSTFREE','CUST']
                },
                {
                    displayName: 'Deliveries',
                    icon: 'mdi-truck-delivery',
                    name: 'deliveries',
                    singleName: 'delivery',
                    archiveName: 'delivery-archives',
                    subscriptions: ['COUR'],
                    permissions: ['deliveries'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Deliveries',
                    subFilters: ['COUR','SUPP']
                },
                {
                    displayName: 'Delivery Archives',
                    isInNavMenu: false,
                    icon: 'mdi-truck-delivery',
                    name: 'delivery-archives',
                    singleName: 'delivery-archive',
                    subscriptions: ['COUR'],
                    permissions: ['deliveries'],
                    microservice: microservices.COURIERARCHIVING,
                    path: 'CourierArchiving/Deliveries',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Journeys',
                    icon: 'mdi-car-shift-pattern',
                    name: 'journeys',
                    singleName: 'journey',
                    archiveName: 'journey-archives',
                    subscriptions: ['COUR'],
                    permissions: ['journeys'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Journeys',
                    subFilters: ['COUR','SUPP']
                },
                {
                    isInNavMenu: false,
                    name: 'driver-journeys',
                    permissions: ['driver'],
                    microservice: microservices.COURIERING,
                    path: 'Driver/Journeys',
                },
                {
                    displayName: 'Journey Archives',
                    isInNavMenu: false,
                    icon: 'mdi-car-shift-pattern',
                    name: 'journey-archives',
                    singleName: 'journey-archive',
                    subscriptions: ['COUR'],
                    permissions: ['journeys'],
                    microservice: microservices.COURIERARCHIVING,
                    path: 'CourierArchiving/Journeys',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Journey Pointing',
                    isInNavMenu: false,
                    name: 'journey-pointers',
                    singleName: 'journey-pointer',
                    icon: 'mdi-vector-point',
                    subscriptions: ['COUR'],
                    permissions: ['journey-pointers'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/JourneyPointers',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Journey Templates',
                    icon: 'mdi-lock-pattern',
                    name: 'journey-templates',
                    singleName: 'journey-template',
                    subscriptions: ['COUR'],
                    permissions: ['journey-templates'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/JourneyTemplates',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Courier Pointers',
                    isInNavMenu: false,
                    icon: 'mdi-vector-point',
                    name: 'courier-pointers',
                    singleName: 'courier-pointer',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['courier-selection-pointers'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/SelectionPointers',
                    subFilters: ['COUR','SUPP']
                },
                {
                    displayName: 'Route Pointers',
                    isInNavMenu: false,
                    name: 'route-pointers',
                    singleName: 'route-pointer',
                    icon: 'mdi-map-marker-path',
                    subscriptions: ['COUR'],
                    permissions: ['route-pointers'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/RoutePointers',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Routes',
                    isInNavMenu: false,
                    name: 'routes',
                    singleName: 'route',
                    icon: 'mdi-map-marker-path',
                    subscriptions: ['COUR'],
                    permissions: ['route-pointers'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Routes',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Courier Actions',
                    icon: 'mdi-function',
                    name: 'courier-actions',
                    singleName: 'courier-action',
                    subscriptions: ['COUR'],
                    permissions: ['courier-functions'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/FunctionCommands',
                    subFilters: ['COUR']
                },
            ]
        },
        {
            displayName: 'Invoicing',
            icon: 'mdi-receipt',
            children: [
                {
                    displayName: 'Customer Invoices',
                    icon: 'mdi-receipt',
                    name: 'customer-invoices',
                    singleName: 'customer-invoice',
                    subscriptions: ['SUPP'],
                    permissions: ['customer-invoices'],
                    microservice: microservices.ORDERINVOICING,
                    path: 'Supplier/Invoices',
                    subFilters: ['COUR','SUPP']
                },
                {
                    displayName: 'Supplier Invoices',
                    icon: 'mdi-receipt',
                    name: 'supplier-invoices',
                    singleName: 'supplier-invoice',
                    subscriptions: ['CUSTFREE', 'CUST'],
                    permissions: ['supplier-invoices'],
                    microservice: microservices.ORDERINVOICING,
                    path: 'Customer/Invoices'
                },
                {
                    displayName: 'Billable Items',
                    icon: 'mdi-receipt',
                    name: 'client-billable-items',
                    singleName: 'client-billable-item',
                    subscriptions: ['COUR'],
                    permissions: ['client-billable-items'],
                    microservice: microservices.COURIERINVOICING,
                    path: 'Courier/BillableItems',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Billable Items',
                    icon: 'mdi-receipt',
                    name: 'courier-billable-items',
                    singleName: 'courier-billable-item',
                    subscriptions: ['CUSTFREE', 'CUST', 'SUPP'],
                    permissions: ['courier-billable-items'],
                    microservice: microservices.COURIERINVOICING,
                    path: 'Client/BillableItems',
                    subFilters: ['CUSTFREE','CUST', 'SUPP']
                },
                {
                    displayName: 'Client Invoices',
                    icon: 'mdi-receipt',
                    name: 'client-invoices',
                    singleName: 'client-invoice',
                    subscriptions: ['COUR'],
                    permissions: ['client-items'],
                    microservice: microservices.COURIERINVOICING,
                    path: 'Courier/Invoices',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Courier Invoices',
                    icon: 'mdi-receipt',
                    name: 'courier-invoices',
                    singleName: 'courier-invoice',
                    subscriptions: ['CUSTFREE', 'CUST', 'SUPP'],
                    permissions: ['courier-invoices'],
                    microservice: microservices.COURIERINVOICING,
                    path: 'Client/Invoices',
                    subFilters: ['CUSTFREE','CUST', 'SUPP']
                },
                {
                    displayName: 'Invoice Actions',
                    icon: 'mdi-electron-framework',
                    name: 'order-invoicing-actions',
                    singleName: 'order-invoicing-action',
                    subscriptions: ['SUPP'],
                    permissions: ['order-invoicing-functions'],
                    microservice: microservices.ORDERINVOICING,
                    path: 'OrderInvoicing/FunctionCommands',
                    subFilters: ['SUPP']
                }
            ]
        },
        {
            displayName: 'Business',
            icon: 'mdi-domain',
            children: [
                {
                    displayName: 'Customers',
                    name: 'customers',
                    aliases: ['customer-locations'],
                    icon: 'mdi-account-box',
                    singleName: 'customer',
                    subscriptions: ['SUPP'],
                    permissions: ['customers'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/OrderAgreements',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'New Customers',
                    isInNavMenu: false,
                    name: 'new-customers',
                    icon: 'mdi-account-multiple-plus',
                    permissions: 'create-customers',
                    microservice: microservices.AUTH,
                    path: 'Accounts',
                },
                {
                    displayName: 'Customer Templates',
                    icon: 'mdi-account-edit-outline',
                    name: 'customer-templates',
                    singleName: 'customer-template',
                    subscriptions: ['SUPP'],
                    permissions: ['customer-templates'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/AgreementTemplates',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Customer Groups',
                    icon: 'mdi-account-group',
                    name: 'customer-groups',
                    singleName: 'customer-group',
                    subscriptions: ['SUPP'],
                    permissions: ['customers'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/OrderAgreementGroups',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Suppliers',
                    name: 'suppliers',
                    icon: 'mdi-factory',
                    singleName: 'supplier',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['suppliers'],
                    microservice: microservices.ORDERING,
                    path: 'Customer/OrderAgreements',
                    subFilters: ['CUSTFREE','CUST','SUPP']
                },
                {
                    displayName: 'New Suppliers',
                    isInNavMenu: false,
                    name: 'new-suppliers',
                    icon: 'mdi-account-multiple-plus',
                    permissions: 'create-suppliers',
                    microservice: microservices.AUTH,
                    path: 'Accounts',
                },
                {
                    displayName: 'Clients',
                    name: 'clients',
                    icon: 'mdi-account-network',
                    singleName: 'client',
                    subscriptions: ['COUR'],
                    permissions: ['clients'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/CourierAgreements',
                    subFilters: ['COUR','SUPP']
                },
                {
                    displayName: 'New Clients',
                    isInNavMenu: false,
                    name: 'new-clients',
                    icon: 'mdi-account-multiple-plus',
                    permissions: 'create-clients',
                    microservice: microservices.AUTH,
                    path: 'Accounts',
                },
                {
                    displayName: 'Client Invoice Settings',
                    isInNavMenu: false,
                    name: 'clients-invoicing',
                    subscriptions: ['COUR'],
                    permissions: ['clients'],
                    microservice: microservices.COURIERINVOICING,
                    path: 'Courier/InvoicingAgreements'
                },
                {
                    displayName: 'Client Invoice Settings',
                    isInNavMenu: false,
                    name: 'clients-fulfilling',
                    subscriptions: ['COUR'],
                    permissions: ['clients'],
                    microservice: microservices.ORDERING,
                    path: 'Fulfiller/Agreements'
                },
                {
                    displayName: 'Client Templates',
                    icon: 'mdi-account-edit-outline',
                    name: 'client-templates',
                    singleName: 'client-template',
                    subscriptions: ['COUR'],
                    permissions: ['client-templates'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/AgreementTemplates',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Couriers',
                    name: 'couriers',
                    icon: 'mdi-map-marker-distance',
                    singleName: 'courier',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['couriers'],
                    microservice: microservices.COURIERING,
                    path: 'Client/CourierAgreements',
                },
                {
                    displayName: 'Fulfiller Settings',
                    isInNavMenu: false,
                    name: 'couriers-fulfilling',
                    subscriptions: ['COUR'],
                    permissions: ['couriers'],
                    microservice: microservices.ORDERING,
                    path: 'Client/FulfillerAgreements'
                },
                {
                    displayName: 'New Couriers',
                    isInNavMenu: false,
                    name: 'new-couriers',
                    icon: 'mdi-account-multiple-plus',
                    permissions: 'create-couriers',
                    microservice: microservices.AUTH,
                    path: 'Accounts',
                },
                {
                    displayName: 'Suppliers',
                    isInNavMenu: false,
                    requiresAuth: false,
                    name: 'public-suppliers',
                    singleName: 'public-supplier',
                    background: '',
                    microservice: microservices.ORDERING,
                    path: 'Public/Suppliers'
                },
                {
                    displayName: 'Couriers',
                    isInNavMenu: false,
                    requiresAuth: false,
                    name: 'public-couriers',
                    singleName: 'public-courier',
                    microservice: microservices.COURIERING,
                    path: 'Public/Couriers'
                },
                {
                    displayName: 'Fulfillers',
                    isInNavMenu: false,
                    requiresAuth: false,
                    name: 'public-fulfillers',
                    singleName: 'public-fulfiller',
                    microservice: microservices.COURIERING,
                    path: 'Public/Fulfillers'
                },
                {
                    displayName: 'Products',                
                    isInNavMenu: false,                                    
                    requiresAuth: false,                
                    name: 'public-products',
                    singleName: 'public-product',   
                    microservice: microservices.ORDERING,             
                    path: 'Public/Products',                      
                },
                {
                    displayName: 'Links',
                    isInNavMenu: false,
                    icon: 'mdi-handshake-outline',
                    name: 'sign-over-links',
                    singleName: 'sign-over-link',
                    permissions: ['account-links'],
                    microservice: microservices.AUTH,
                    path: 'Account/Links'
                },
                {
                    displayName: 'Requests Received',
                    isInNavMenu: false,
                    name: 'customer-requests',
                    singleName: 'customer-request',
                    subscriptions: ['SUPP'],
                    permissions: ['customers'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/Requests'
                },
                {
                    displayName: 'Requests Received',
                    isInNavMenu: false,
                    name: 'client-requests',
                    singleName: 'client-request',
                    subscriptions: ['COUR'],
                    permissions: ['clients'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Requests'
                },   
                {
                    displayName: 'Requests Sent',
                    isInNavMenu: false,
                    name: 'courier-requests',
                    singleName: 'courier-request',
                    subscriptions: ['COUR'],
                    permissions: ['couriers'],
                    microservice: microservices.COURIERING,
                    path: 'Client/Requests'                    
                },  
                {
                    displayName: 'Requests Sent',
                    isInNavMenu: false,
                    name: 'supplier-requests',
                    singleName: 'supplier-request',
                    subscriptions: ['CUST'],
                    permissions: ['suppliers'],
                    microservice: microservices.ORDERING,
                    path: 'Customer/Requests'                    
                },
                // {
                //     displayName: 'Requests',
                //     name: 'requests',  
                //     icon: 'mdi-comment-question',                  
                //     subscriptions: ['CUST', 'COUR', 'SUPP'],
                //     permissions: ['customers', 'suppliers', 'clients', 'couriers'],
                //     children: [
                          
                //     ]
                // },
                {
                    displayName: 'Details',
                    isInNavMenu: false,
                    name: 'managed-company-details',
                    // subscriptions: ['CUST'],
                    permissions: ['company-details'],
                    microservice: microservices.AUTH,
                    path: 'Account/MyCompany'
                },
                {
                    displayName: 'Stock Settings',
                    isInNavMenu: false,
                    name: 'customers-stock',
                    subscriptions: ['SUPP'],
                    permissions: ['customers'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Supplier/StockAgreements',
                },
                {
                    displayName: 'Dispatch Settings',
                    isInNavMenu: false,
                    name: 'customers-dispatch',
                    subscriptions: ['SUPP'],
                    permissions: ['customers'],
                    microservice: microservices.COURIERING,
                    path: 'Supplier/DispatchAgreements',
                },
                {
                    displayName: 'Dispatch Settings',
                    isInNavMenu: false,
                    name: 'suppliers-dispatch',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['suppliers'],
                    microservice: microservices.COURIERING,
                    path: 'Customer/DispatchAgreements',
                },
                {
                    displayName: 'Products',
                    isInNavMenu: false,
                    name: 'customer-products',
                    subscriptions: ['SUPP'],
                    permissions: ['customers'],
                    microservice: microservices.ORDERING,
                    path: 'Agreement/Products',
                },
                {
                    displayName: 'Customer Orders',
                    isInNavMenu: false,
                    name: 'customer-single-orders',
                    subscriptions: ['SUPP'],
                    microservice: microservices.ORDERING,
                    path: 'CustomerOrders'
                },
                {
                    displayName: 'Standing Orders',
                    isInNavMenu: false,
                    name: 'customer-single-standing-orders',
                    subscriptions: ['SUPP'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/StandingOrders'
                },
                {
                    displayName: 'Order Slots',
                    isInNavMenu: false,
                    name: 'customer-single-order-slots',
                    subscriptions: ['SUPP'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/StandingOrders'
                }
            ]
        },
        {
            displayName: "Items",
            icon: 'mdi-view-list',
            children: [
                {
                    displayName: 'Automatic Line Items',
                    name: 'automatic-line-items',
                    icon: 'mdi-auto-fix',
                    singleName: 'automatic-line-item',
                    subscriptions: ['SUPP'],
                    permissions: ['automatic-line-items'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/AutomaticLineItems',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Automatic Line Item Groups',
                    name: 'automatic-line-item-groups',
                    icon: 'mdi-auto-fix',
                    singleName: 'automatic-line-item-group',
                    subscriptions: ['SUPP'],
                    permissions: ['automatic-line-items'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/AutomaticLineItemGroups',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Delivery Fees',
                    name: 'delivery-fees',
                    icon: 'mdi-credit-card-marker',
                    singleName: 'delivery-fee',
                    subscriptions: ['SUPP'],
                    permissions: ['automatic-line-items'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/AutomaticLineItems',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Locations',
                    name: 'locations',
                    icon: 'mdi-map-marker',
                    singleName: 'location',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['locations'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Locations',
                    subFilters: ['CUSTFREE','CUST','COUR','SUPP']
                },
                {
                    displayName: 'Delivery Location',
                    isInNavMenu: false,
                    name: 'delivery-locations',
                    singleName: 'delivery-location',
                    icon: 'mdi-map-marker',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['locations'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Locations'
                },
                {
                    displayName: 'Delivery Location Notes',
                    isInNavMenu: false,
                    name: 'location-notes',
                    singleName: 'location-note',
                    icon: 'mdi-note-text',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['location-notes'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/LocationNotes'
                },
                {
                    displayName: 'Customer Location',
                    isInNavMenu: false,
                    name: 'customer-default-location',
                    icon: 'mdi-map-marker',
                    singleName: 'customer-default-location',
                    subscriptions: ['SUPP'],
                    permissions: ['customers'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Locations'
                },
                {
                    displayName: 'Measurements',
                    name: 'measurements',
                    icon: 'mdi-tape-measure',
                    singleName: 'measurement',
                    subscriptions: ['COUR'],
                    permissions: ['measurements'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Measurements',
                    subFilters: ['COUR', 'SUPP']
                },
                {
                    isInNavMenu: false,
                    displayName: 'Measurement Standards',
                    name: 'measurement-standards',
                    singleName: 'measurement-standard',
                    microservice: microservices.COURIERING,
                    path: 'MeasurementStandards',
                    storeID: 'standard'
                },
                {
                    displayName: 'Order Rules',
                    name: 'ordering-rules-groups',
                    icon: 'mdi-ruler',
                    singleName: 'ordering-rules-group',
                    subscriptions: ['SUPP'],
                    permissions: ['order-rules'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/OrderingRules',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Order Sheets',
                    name: 'order-sheets',
                    icon: 'mdi-list-box',
                    singleName: 'order-sheet',
                    subscriptions: ['SUPP'],
                    permissions: ['order-sheets'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/OrderSheets',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Order Slots',
                    name: 'order-slots',
                    icon: 'mdi-timer',
                    singleName: 'order-slot',
                    subscriptions: ['SUPP'],
                    permissions: ['order-slots'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/OrderSlots',
                    subFilters: ['SUPP']
                },
                // {
                //     displayName: 'Order Slot Groups',
                //     isInNavMenu: false,
                //     name: 'order-slot-groups',
                //     icon: 'mdi-timer',
                //     singleName: 'order-slot',
                //     subscriptions: ['SUPP'],
                //     permissions: ['order-slot-groups'],
                //     microservice: microservices.ORDERING,
                //     path: 'Supplier/OrderSlotGroups',
                //     subFilters: ['SUPP']
                // },
                {
                    displayName: 'Price Tiers',
                    icon: 'mdi-currency-usd',
                    name: 'supplier-price-tiers',
                    singleName: 'supplier-price-tier',
                    subscriptions: ['SUPP'],
                    permissions: ['supplier-price-tiers'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/PriceTiers',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Payment Terms',
                    icon: 'mdi-currency-usd',
                    name: 'supplier-payment-terms',
                    singleName: 'supplier-payment-term',
                    subscriptions: ['SUPP'],
                    permissions: ['supplier-payment-terms'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/PaymentTerms',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Price Tiers',
                    icon: 'mdi-currency-usd',
                    name: 'courier-price-tiers',
                    singleName: 'courier-price-tier',
                    subscriptions: ['COUR'],
                    permissions: ['courier-price-tiers'],
                    microservice: microservices.COURIERINVOICING,
                    path: 'Courier/PriceTiers',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Payment Terms',
                    icon: 'mdi-currency-usd',
                    name: 'courier-payment-terms',
                    singleName: 'courier-payment-terms',
                    subscriptions: ['COUR'],
                    permissions: ['courier-payment-terms'],
                    microservice: microservices.COURIERINVOICING,
                    path: 'Courier/PaymentTerms',
                    subFilters: ['COUR']
                },
                {
                    displayName: 'Products',
                    icon: 'mdi-cube-outline',
                    name: 'products',
                    singleName: 'product',
                    subscriptions: ['CUST', 'SUPP'],
                    permissions: ['products'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'Products',
                    subFilters: ['CUST', 'SUPP']
                },
                {
                    displayName: 'New Products',
                    isInNavMenu: false,
                    name: 'new-products',
                    icon: 'mdi-cube',
                    permissions: 'products',
                    microservice: microservices.STOCKMANAGING,
                    path: 'Products',
                },
                {
                    displayName: 'Public Settings',
                    isInNavMenu: false,
                    name: 'product-ordering',
                    subscriptions: ['SUPP'],
                    permissions: ['products'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/Products',
                },
                {
                    displayName: 'Public Categories',
                    isInNavMenu: false,
                    name: 'public-product-categories',
                    permissions: ['products'],
                    microservice: microservices.ORDERING,
                    path: 'Public/ProductCategories',
                },
                {
                    displayName: 'Product Categories',
                    icon: 'mdi-shape-outline',
                    name: 'product-categories',
                    singleName: 'product-category',
                    subscriptions: ['SUPP'],
                    permissions: ['products'],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/ProductCategories',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Product Groups',
                    icon: 'mdi-cube-scan',
                    name: 'product-groups',
                    singleName: 'product-group',
                    subscriptions: ['SUPP'],
                    permissions: [PM.PRODUCTOFFERINGGROUP],
                    microservice: microservices.ORDERING,
                    path: 'Supplier/ProductOfferingGroups',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Product Tethers',
                    icon: 'mdi-vector-link',
                    name: 'product-tethers',
                    singleName: 'product-tether',
                    subscriptions: ['SUPP'],
                    permissions: ['product-tethers'],
                    microservice: microservices.STOCKMANAGING,
                    path: 'ProductTethers',
                    subFilters: ['SUPP']
                },
                {
                    displayName: 'Zones',
                    icon: 'mdi-texture-box',
                    name: 'zones',
                    singleName: 'zone',
                    subscriptions: ['COUR'],
                    permissions: [PM.COURIERSETTINGS],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Zones',
                    subFilters: ['COUR']
                }
            ]
        },
        {
            displayName: 'Account',
            icon: 'mdi-account-box',
            permissions: ['user-roles', 'users', ],
            children: [
                {
                    displayName: 'My Profile',
                    isInNavMenu: false,
                    name: 'my-profile',
                    icon: 'mdi-account',
                    subscriptions: ['CUSTFREE'],
                    microservice: microservices.AUTH,
                    path: 'Account/MyLogin',
                },                
                {
                    displayName: 'Roles',
                    isInNavMenu: false,
                    name: 'roles',
                    icon: 'mdi-badge-account',
                    singleName: 'role',
                    subscriptions: ['CUSTFREE'],
                    permissions: ['user-roles'],
                    microservice: microservices.AUTH,
                    path: 'Account/Roles'
                },
                {
                    displayName: 'Users',
                    name: 'users', 
                    icon: 'mdi-account-group',
                    singleName: 'user',        
                    subscriptions: ['CUSTFREE'],            
                    permissions: ['users'],
                    microservice: microservices.AUTH,
                    path: 'Account/Users'
                },
                {
                    displayName: '3rd Party Integration',
                    name: 'external-party-options',
                    singleName: 'external-party-option',
                    icon: 'mdi-account-network',
                    subscriptions: ['CUSTFREE'],
                    permissions: [PM.EXTPARTY],
                    microservice: microservices.AUTH,
                    path: 'Auth/ExternalPartyOptions'
                },
                {
                    isInNavMenu: false,
                    name: 'external-party-integration',
                },
                {
                    displayName: 'Invoice Mappings',
                    isInNavMenu: false,
                    name: 'order-invoice-mappings',
                    singleName: 'order-invoice-mapping',
                    icon: 'mdi-source-branch-sync',
                    subscriptions: ['SUPP'],
                    permissions: ['order-invoicing-external-mappings'],
                    microservice: microservices.ORDERINVOICING,
                    path: 'OrderInvoicing/ExternalMappings'
                },
                {
                    displayName: 'Subscription Invoices',
                    isInNavMenu: false,
                    icon: 'mdi-account-cash',
                    name: 'subscription-invoices',
                    singleName: 'subscription-invoice',
                    permissions: ['subscription-invoices'],
                    microservice: microservices.BILLING,
                    path: 'Billing/Invoices'
                },
                {
                    displayName: 'Configurations',
                    icon: 'mdi-bell-cog',
                    name: 'notification-configurations',
                    permissions: ['notification-configurations'],
                },
                {
                    displayName: 'Settings',
                    name: 'settings',
                    icon: 'mdi-cog',
                    subscriptions: ['CUSTFREE', 'COUR', 'SUPP'],
                    permissions: [PM.SUBSCRIPTION, PM.CUSTOMERORDERSETTING, PM.SUPPLIERORDERSETTING, PM.CUSTOMERORDERSETTING, PM.STOCKMANAGEMENTSETTING],
                    // microservice: microservices.AUTH,
                    // path: 'Account/MyCompany',
                    children: [
                        {
                            displayName: 'Company Settings',
                            isInNavMenu: false,
                            name: 'company-settings',
                            icon: 'mdi-cog',
                            subscriptions: ['CUSTFREE'],
                            permissions: [PM.COMPANYDETAILS], 
                            microservice: microservices.AUTH,
                            path: 'Account/MyCompany'
                        },
                        {
                            displayName: 'Current Subscription',
                            isInNavMenu: false,
                            name: 'subscription-settings',
                            icon: 'mdi-sim-outline',
                            subscriptions: ['CUSTFREE'],
                            permissions: [PM.SUBSCRIPTION],
                            microservice: microservices.AUTH,
                            path: 'Account/MySubscription'
                        },
                        {
                            displayName: 'Billing Settings',
                            isInNavMenu: false,
                            name: 'billing-settings',
                            icon: 'mdi-credit-card-outline',
                            subscriptions: ['CUSTFREE'],
                            permissions: [PM.SUBSCRIPTION],
                            microservice: microservices.BILLING,
                            path: 'Billing/Settings',
                        },
                        {
                            displayName: 'Customer Settings',
                            isInNavMenu: false,
                            name: 'customer-settings',
                            icon: 'mdi-account',
                            subscriptions: ['CUSTFREE'],
                            permissions: [PM.CUSTOMERORDERSETTING],
                            microservice: microservices.ORDERING,
                            path: 'Customer/Settings'
                        },
                        {
                            displayName: 'Courier Settings',
                            isInNavMenu: false,
                            name: 'courier-settings',
                            icon: 'mdi-map-marker-distance',
                            subscriptions: ['COUR'],
                            permissions: ['courier-settings'],
                            microservice: microservices.COURIERING,
                            path: 'Courier/Settings'
                        },                        
                        {
                            displayName: 'Supplier Settings',         
                            isInNavMenu: false,                   
                            name: 'supplier-settings',
                            icon: 'mdi-factory',
                            subscriptions: ['SUPP'],
                            permissions: [PM.SUPPLIERORDERSETTING],
                            microservice: microservices.ORDERING,
                            path: 'Supplier/Settings'
                        },
                        {
                            displayName: 'Invoicing Settings',             
                            isInNavMenu: false,               
                            name: 'invoicing-settings',
                            icon: 'mdi-receipt',
                            subscriptions: ['CUSTFREE'],
                            permissions: [PM.CUSTOMERORDERSETTING],
                            microservice: microservices.ORDERINVOICING,
                            path: 'OrderInvoicing/Settings'                            
                        },
                        {
                            displayName: 'Courier Invoicing Settings',             
                            isInNavMenu: false,               
                            name: 'courier-invoicing-settings',
                            icon: 'mdi-receipt',
                            subscriptions: ['COUR'],
                            permissions: [PM.COURIERSETTINGS],
                            microservice: microservices.COURIERINVOICING,
                            path: 'CourierInvoicing/Settings'
                        },
                        {
                            displayName: 'Stock Level Settings',          
                            isInNavMenu: false,                  
                            name: 'stock-level-settings',
                            icon: 'mdi-clipboard-pulse',
                            subscriptions: ['CUSTFREE'],
                            permissions: [PM.STOCKMANAGEMENTSETTING],
                            microservice: microservices.STOCKTRACKING,
                            path: 'StockTracking/Settings'                                                     
                        },
                        {
                            displayName: 'Pick/Pack Settings',
                            isInNavMenu: false,
                            name: 'picking-settings',
                            icon: 'mdi-clipboard-check-outline',
                            subscriptions: ['SUPP'],
                            permissions: [PM.STOCKMANAGEMENTSETTING],
                            microservice: microservices.STOCKMANAGING,
                            path: 'Stock/Settings'
                        },
                    ]
                },
            ]
        },
        {
            displayName: 'Demo',
            isInNavMenu: false,
            name: 'demo',
            icon: 'mdi-school',
            background: null, //'training',
            isAuth: false,
            isTraining: true,
        },
        {
            isInNavMenu: false,
            children: [
                // {
                //     name: 'browse-suppliers',
                //     singleName: 'browse-supplier',
                //     background: null,
                // },
                {
                    name: 'help-links',
                    singleName: 'help-link',
                    subscriptions: ['SUPP','COUR','CUST'],
                    permissions: ['help-links'],
                    microservice: microservices.BILLING,
                    path: 'Billing/HelpLinks'
                },
                {
                    displayName: 'Ordering',
                    icon: 'mdi-receipt',
                    name: 'purchasing-orders',
                    singleName: 'purchasing-order',
                    subscriptions: ['CUSTFREE','CUST'],
                    permissions: ['purchaser'],
                    microservice: microservices.ORDERING,
                    path: 'PurchaseOrders',
                },
                {
                    name: 'route-suggestions',
                    microservice: microservices.COURIERING,
                    path: 'Courier/RouteSuggestions'
                },
                { 
                    name: 'bundle-suggestions',
                    microservice: microservices.COURIERING,
                    path: 'Courier/BundleSuggestions'
                },
                {
                    name: 'journey-suggestions',
                    microservice: microservices.COURIERING,
                    path: 'Courier/JourneySuggestions',
                    storeID: 'journeyID'
                },
                {
                    name: 'subscription-plans',
                    microservice: microservices.BILLING,
                    path: 'Billing/SubscriptionPlans'
                },
                {
                    name: 'account',
                    microservice: microservices.AUTH,
                    path: 'Account'
                },
                {
                    name: 'automatic-line-item-syncing',
                    microservice: microservices.ORDERING,
                    path: 'Supplier/AutomaticLineItems/ExternalPartySyncing',
                    externalPartyCredentialNavigation: 'external-party-credentials',
                    externalParties: [{ party: 'xero', property: 'xeroID' }],
                    externalNavigations: [
                        {
                            name: 'xero',
                            //local item paths are found through navigation
                            localNavigation: 'automatic-line-items',
                            localDisplayPath: 'lineItemName',
                            localComparePath: 'description',
                            //syncPath: '',
                            syncDisplayPath: 'name',
                            syncComparePath: 'code',
                            canPull: true,
                            canPush: true
                        }
                    ]
                },
                {
                    name: 'delivery-fee-syncing',
                    microservice: microservices.ORDERING,
                    path: 'Supplier/DeliveryFees/ExternalPartySyncing',
                    externalPartyCredentialNavigation: 'external-party-credentials',
                    externalParties: [{ party: 'xero', property: 'xeroID' }],
                    externalNavigations: [
                        {
                            name: 'xero',
                            //local item paths are found through navigation
                            localNavigation: 'automatic-line-items',
                            localDisplayPath: 'description',
                            localComparePath: 'description',
                            //syncPath: '',
                            syncDisplayPath: 'name',
                            syncComparePath: 'code',
                            canPull: true,
                            canPush: true
                        }
                    ]
                },
                {
                    name: 'email-configurations',
                    permissions: 'supplier-settings',
                    microservice: microservices.ORDERING,
                    path: 'Supplier/EmailConfigurations',
                    subscriptions: ['COUR', 'SUPP'],
                },
                {
                    isInNavMenu: false,
                    displayName: 'Notification Configuration',
                    name: 'stock-notification-configurations',
                    permissions: 'notification-configurations',
                    microservice: microservices.STOCKMANAGING,
                    path: 'Stock/NotificationConfigurations'
                },
                {
                    isInNavMenu: false,
                    displayName: 'Notification Configuration',
                    name: 'order-invoicing-notification-configurations',
                    permissions: 'notification-configurations',
                    microservice: microservices.ORDERINVOICING,
                    path: 'OrderInvoicing/NotificationConfigurations'
                },
                {
                    isInNavMenu: false,
                    displayName: 'Notification Configuration',
                    name: 'ordering-notification-configurations',
                    permissions: 'notification-configurations',
                    microservice: microservices.ORDERING,
                    path: 'Ordering/NotificationConfigurations'
                },
                {
                    isInNavMenu: false,
                    displayName: 'Notification Configuration',
                    name: 'courier-notification-configurations',
                    permissions: 'notification-configurations',
                    microservice: microservices.COURIERING,
                    path: 'Courier/NotificationConfigurations'
                },
                {
                    name: 'dispatch-journey',
                    permissions: ['dispatcher']
                },
                {
                    name: 'dispatch-pickup',
                    permissions: ['driver', 'dispatcher'],
                },
                {
                    name: 'dispatch-releases',
                    singleName: 'dispatch-release',
                    permissions: ['dispatcher'],
                    microservice: microservices.COURIERING,
                    path: 'Courier/Releases',
                },
                {
                    name: 'company-image',
                    microservice: microservices.AUTH,
                    path: 'Account/MyCompany/Upload/Upload'
                },
                {
                    name: 'company-banner',
                    microservice: microservices.AUTH,
                    path: 'Account/MyCompany/Banner/Upload'
                },
                {
                    name: 'company-ordering-background',
                    microservice: microservices.AUTH,
                    path: 'Account/MyCompany/OrderingBackground/Upload'
                },
                {
                    name: 'customer-image',
                    microservice: microservices.AUTH,
                    path: 'Account/MyCompany/Upload/Upload'
                },
                {
                    name: 'customer-syncing',
                    microservice: microservices.ORDERING,
                    path: 'Supplier/OrderAgreements/ExternalPartySyncing',
                    externalPartyCredentialNavigation: 'external-party-credentials',
                    externalParties: [
                        { party: 'xero' },
                        { party: 'shopify' }],
                    externalNavigations: [
                        {
                            name: 'xero',
                            //local item paths are found through navigation
                            localNavigation: 'customers',
                            localDisplayPath: 'buyer.companyName',
                            localComparePath: 'buyer.companyName',
                            //syncPath: '',
                            syncDisplayPath: 'name',
                            syncComparePath: 'name',
                            syncIDPath: 'contactID',
                            canPull: true,
                            canPush: true,
                            convertFunc: (extItem) => {
                                var cust = {
                                    companyName: extItem.name,
                                    addressLineOne: null,
                                    streetNumber: null,
                                    streetName: null,
                                    suburb: null,
                                    state: null,
                                    postcode: null,
                                    email: extItem.emailAddress,
                                    phoneNumber: null,
                                    lat: null,
                                    lng: null,
                                    externalReferences: [
                                        {
                                            localID: null,
                                            externalParty: 'xero',
                                            externalID: extItem.contactID,
                                            data: null
                                        }
                                    ]
                                };

                                if (extItem.phones != null && extItem.phones.length > 0) {
                                    var ph = extItem.phones.find(x => x.phoneNumber != null && x.phoneNumber.length > 1);
                                    if (ph != null) {
                                        cust.phoneNumber = ph.phoneNumber;
                                    }
                                }

                                if (extItem.addresses != null && Array.isArray(extItem.addresses) && extItem.addresses.length > 0) {
                                    var address = extItem.addresses.find(x => x.city != null && x.city.length > 1);
                                    if (address != null) {
                                        cust.suburb = address.city;
                                        cust.postcode = address.postalCode;
                                        cust.state = address.region;

                                        var streetLine = null;

                                        if (address.addressLine1 != null && address.addressLine1.length > 1 && address.addressLine2 != null && address.addressLine2.length > 1) {
                                            cust.addressLine1 = address.addressLine1;
                                            streetLine = address.addressLine2;
                                        }
                                        else {
                                            streetLine = address.addressLine1;
                                        }

                                        if (streetLine != null && streetLine.length > 1) {
                                            var streetArr = streetLine.split(' ');
                                            if (streetArr.length > 1) {
                                                cust.streetNumber = streetArr[0];
                                                cust.streetName = streetLine.substring(cust.streetNumber.length + 1);
                                            }
                                        }
                                    }
                                }

                                return cust;
                            }
                        },
                        {
                            name: 'shopify',
                            localNavigation: 'customers',
                            localDisplayPath: 'buyer.companyName',
                            localComparePath: 'buyer.primaryEmail',
                            syncDisplayPath: 'lastName',
                            syncComparePath: 'email',
                            syncIDPath: 'id',
                            canPull: true,
                            canPush: true
                        }
                    ]
                },
                {
                    name: 'supplier-syncing',
                    microservice: microservices.ORDERING,
                    path: 'Customer/OrderAgreements/ExternalPartySyncing',
                    externalPartyCredentialNavigation: 'external-party-credentials',
                    externalParties: [{ party: 'xero', property: 'xeroID' }],
                    externalNavigations: [
                        {
                            name: 'xero',
                            //local item paths are found through navigation
                            localNavigation: 'supplier',
                            localDisplayPath: 'seller.companyName',
                            localComparePath: 'seller.companyName',
                            //syncPath: '',
                            syncDisplayPath: 'name',
                            syncComparePath: 'name',
                            canPull: true,
                            canPush: true
                        }
                    ]
                },
                {
                    name: 'customer-dispatch-settings',
                    icon: 'mdi-account-box',
                    singleName: 'customer',
                    subscriptions: ['SUPP'],
                    permissions: ['customer-settings'],
                    microservice: microservices.COURIERING,
                    path: 'Supplier/OrderAgreements',
                    subFilters: ['SUPP']
                },
                {
                    name: 'external-party-credentials',
                    microservice: microservices.AUTH,
                    path: 'Auth/ExternalPartyCredentials'
                },
                {
                    name: 'customer-invoice-syncing',
                    microservice: microservices.ORDERINVOICING,
                    path: 'Supplier/Invoices/ExternalPartySyncing',
                    externalPartyCredentialNavigation: 'external-party-credentials',
                    externalParties: [
                        { party: 'xero' }],
                    externalNavigations: [
                        {
                            name: 'xero',
                            //local item paths are found through navigation
                            localNavigation: 'customer-invoices',
                            localDisplayPath: 'buyer.companyName',
                            localComparePath: 'invoiceNumber',
                            //syncPath: '',
                            syncDisplayPath: 'invoiceNumber',
                            syncComparePath: 'invoiceNumber',
                            canPush: true
                        }
                    ]
                },
                {
                    name: 'fulfiller-syncing',
                    microservice: microservices.ORDERING,
                    path: 'Client/FulfillerAgreements/ExternalPartySyncing',
                    externalPartyCredentialNavigation: 'external-party-credentials',
                    externalParties: [
                        { party: 'shopify' }],
                    externalNavigations: [
                        {
                            name: 'shopify',
                            localNavigation: 'couriers-fulfilling',
                            localDisplayPath: 'seller.companyName',
                            localComparePath: 'seller.companyName',
                            syncDisplayPath: 'name',
                            syncComparePath: 'name',
                            syncIDPath: 'id',
                            canPush: true
                        }
                    ]
                },
                {
                    name: 'order-invoicing-external-party-credentials',
                    microservice: microservices.ORDERINVOICING,
                    path: 'OrderInvoicing/ExternalPartyCredentials'
                },
                {
                    name: 'order-sheet-items',
                    microservice: microservices.ORDERING,
                    path: 'Supplier/OrderSheetItems',
                },
                // {
                //     name: 'public-products',
                //     microservice: microservices.ORDERING,
                //     path: 'Public/Products'
                // },
                {
                    isInNavMenu: false,
                    name: 'ordering-user-activities',
                    microservice: microservices.ORDERING,
                    path: 'Ordering/UserActivities'
                },
                {
                    name: 'public-companies',
                    microservice: microservices.AUTH,
                    path: 'Public/Companies',
                    localCacheDays: 30
                },
                {
                    name: 'public-locations',
                    microservice: microservices.STOCKMANAGING,
                    path: 'Public/Locations',
                    localCacheDays: 30
                },
                {
                    name: 'public-users',
                    microservice: microservices.AUTH,
                    path: 'Public/Users'
                },
                {
                    name: 'product-image',
                    microservice: microservices.STOCKMANAGING,
                    path: 'Products/Upload/Upload'
                },
                {
                    name: 'delivery-image',
                    microservice: microservices.COURIERING,
                    path: 'Courier/Deliveries/Upload/Upload'
                },
                {
                    name: 'product-category-image',
                    microservice: microservices.STOCKMANAGING,
                    path: 'Supplier/ProductCategories/Upload/Upload'
                },
                {
                    name: 'product-sources',
                    microservice: microservices.STOCKTRACKING,
                    path: 'StockTracking/Products'
                },
                {
                    name: 'product-syncing',
                    microservice: microservices.STOCKMANAGING,
                    path: 'Products/ExternalPartySyncing',
                    externalPartyCredentialNavigation: 'external-party-credentials',
                    externalParties: [
                        { party: 'xero' },
                        { party: 'shopify' },
                        { party: 'quickbooks' },
                        { party: 'myob' },
                        { party: 'squareup' },
                        { party: 'wix' }],
                    externalNavigations: [
                        {
                            name: 'xero',
                            //local item paths are found through navigation
                            localNavigation: 'products',
                            localDisplayPath: 'productName',
                            localComparePath: 'abbreviation',
                            //syncPath: '',
                            syncDisplayPath: 'code',
                            syncComparePath: 'code',
                            syncIDPath: 'itemID',
                            canPull: true,
                            canPush: true,
                            convertFunc: (extItem) => {
                                return {
                                    id: null,
                                    productName: extItem.name,
                                    abbreviation: extItem.code,
                                    taxType: null,
                                    isSold: extItem.isSold,
                                    isAcquired: extItem.isPurchased,
                                    externalReferences: [
                                        {
                                            localID: null,
                                            externalParty: 'xero',
                                            externalID: extItem.itemID,
                                            data: null
                                        }
                                    ] //localID, externalParty, externalID, data
                                }
                            }
                        },
                        {
                            name: 'shopify',
                            localNavigation: 'products',
                            localDisplayPath: 'productName',
                            localComparePath: 'productName',
                            syncDisplayPath: 'title',
                            syncComparePath: 'title',
                            syncIDPath: 'id',
                            canPull: true,
                            canPush: true,
                            convertFunc: (extItem) => {
                                return {
                                    id: null,
                                    productName: extItem.title,
                                    abbreviation: null, //extItem.sku,
                                    //taxType: extItem.salesTaxCodeRef?.name == 'GST' ? 'GST' : null,
                                    // isSold: extItem.isSold,
                                    // isAcquired: extItem.isPurchased,
                                    //publicDescription: extItem.description,
                                    externalReferences: [
                                        {
                                            localID: null,
                                            externalParty: 'shopify',
                                            externalID: extItem.id,
                                            data: null
                                        }
                                    ] //localID, externalParty, externalID, data
                                }
                            }
                        },
                        {
                            name: 'squareup',
                            localNavigation: 'products',
                            localDisplayPath: 'productName',
                            localComparePath: 'productName',
                            syncDisplayPath: 'itemData.name',
                            syncComparePath: 'itemData.name',
                            syncIDPath: 'id',
                            canPull: true,
                            canPush: true
                        },
                        {
                            name: 'quickbooks',
                            localNavigation: 'products',
                            localDisplayPath: 'productName',
                            localComparePath: 'productName',
                            syncDisplayPath: 'name',
                            syncComparePath: 'name',
                            syncIDPath: 'id',
                            canPull: true,
                            canPush: true,
                            convertFunc: (extItem) => {
                                return {
                                    id: null,
                                    productName: extItem.name,
                                    abbreviation: extItem.sku,
                                    taxType: extItem.salesTaxCodeRef?.name == 'GST' ? 'GST' : null,
                                    // isSold: extItem.isSold,
                                    // isAcquired: extItem.isPurchased,
                                    publicDescription: extItem.description,
                                    externalReferences: [
                                        {
                                            localID: null,
                                            externalParty: 'quickbooks',
                                            externalID: extItem.id,
                                            data: null
                                        }
                                    ] //localID, externalParty, externalID, data
                                }
                            }
                        },
                        {
                            name: 'myob',
                            localNavigation: 'products',
                            localDisplayPath: 'productName',
                            localComparePath: 'productName',
                            syncDisplayPath: 'name',
                            syncComparePath: 'name',
                            syncIDPath: 'uid',
                            canPull: true,
                            canPush: true
                        },
                        {
                            name: 'wix',
                            localNavigation: 'products',
                            localDisplayPath: 'productName',
                            localComparePath: 'productName',
                            syncDisplayPath: 'name',
                            syncComparePath: 'name',
                            syncIDPath: 'id',
                            canPull: true,
                            canPush: true
                        }
                    ]
                },
                {
                    name: 'customer-order-approval',
                    microservice: microservices.ORDERING,
                    path: 'CustomerOrderApprovals'
                },
                {
                    name: 'customer-order-fulfilling',
                    microservice: microservices.ORDERING,
                    path: 'CustomerOrderFulfilling'
                },
                {
                    name: 'customer-order-invoicing',
                    microservice: microservices.ORDERING,
                    path: 'CustomerOrderInvoicing'
                },
                {
                    name: 'stock-entry-item-suggestions',
                    microservice: microservices.STOCKMANAGING,
                    path: 'StockManaging/StockEntryItemSuggestions'
                },
                {
                    name: 'supply-location-suggestions',
                    microservice: microservices.STOCKMANAGING,
                    path: 'StockManaging/SupplyLocationSuggestions'
                },
                {
                    name: 'user-permissions',
                    microservice: microservices.AUTH,
                    path: 'Account/UserPermissions'
                },
                {
                    name: 'quickbooks',
                    microservice: microservices.AUTH,
                    path: 'quickbooks'
                },
                {
                    name: 'myob',
                    microservice: microservices.AUTH,
                    path: 'myob'
                },
                {
                    name: 'shopify',
                    microservice: microservices.AUTH,
                    path: 'shopify'
                },
                {
                    name: 'slack',
                    microservice: microservices.AUTH,
                    path: 'slack'
                },
                {
                    name: 'squareup',
                    microservice: microservices.AUTH,
                    path: 'squareup'
                },
                {
                    name: 'xero',
                    microservice: microservices.AUTH,
                    path: 'xero'
                },
                {
                    name: 'wix',
                    microservice: microservices.AUTH,
                    path: 'wix'
                }
            ]
        }
    ]
}